import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { SimpleCenteredModal } from '../../components';
import ProductCategoryDataTable from './ProductCategoryDataTable';
import ProductCategoryForm from './ProductCategoryForm';
import { createProductCategoryRequest, createInsuranceCompanyProductCategoriesRequest } from '../../requests/productCategories';
import { showCompanyRequest } from '../../requests/companies';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicCompany from '../Company/basicCompany';
import columns from './Columns';

const productCategories = {
  name: '',
  subCategoriesAttributes: []
};

const ProductCategoryIndex = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [currentCompany, setCompany] = useState(basicCompany);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef();

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchCurrentCompany, []);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Categoría creada con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = params => {
    createProductCategoryRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  const renderModalBody = () => (
    <ProductCategoryForm
      productCategories={productCategories}
      action="new"
      submitVariant="success"
      formRequest={handleCreateRequest}
      handleModalClose={handleModalClose}
      dispatch={dispatch}
    />
  );

  const handleModalCreate = () => {
    setModalTitle("Crear Categoría");
    setModalBody(renderModalBody);
    setModalShow(true);
  };

  const createInsuranceCompanyCategory = (insuranceCompanyId, paintPrice) => {
    setOnRequest(true);
    createInsuranceCompanyProductCategoriesRequest({
      dispatch,
      params: {paint_price: paintPrice},
      insuranceCompanyId,
      paintPrice,
      successCallback: () => {
        setOnRequest(false);
        dispatch(sendAlert({ kind: 'success', message: 'Categorías creadas con éxito' }));
        setMoreData(!moreData);
        handleInsuranceCompanyCategoriesModalCreate();
      },
      failureCallback: () => {
        setOnRequest(false);
        handleFailureRequest();
      }
    });
  };

  const handleInsuranceCompanyCategoriesCreate = (ic) => {
    setModalTitle(`Crear Categorías para ${ic.name}`);
    setModalBody(
      <Row>
        <Col md={2} className='px-0 mr-2'>
          Precio pieza de pintura:
        </Col>
        <Col md={3} className='px-0'>
          <input type="number" ref={inputRef} defaultValue={ic.paintPrice} className='h-100'></input>
        </Col>
        <Col md={2} className='px-0'>
          <Button 
            variant="primary" 
            block 
            disabled={onRequest}
            onClick={() => createInsuranceCompanyCategory(ic.id, inputRef.current.value !== '' ? inputRef.current.value : ic.paintPrice)}
          >
            Crear
          </Button>
        </Col>
        {onRequest &&
          <Col>
            <Spinner animation="border" variant="primary" />
          </Col>
        }
      </Row>
    );
    setModalShow(true);
  };

  const renderInsuranceCompanyCategoriesModalBody = () => (
    <Row>
      <Col md={3} className="mb-2">
        <Button className="h-100" variant="secondary" block onClick={() => handleInsuranceCompanyCategoriesCreate({name: 'PARTICULAR', id: 1})}>
          PARTICULAR
        </Button>
      </Col>
      {currentCompany.insuranceCompanies.map(ic => {
        return (
          <Col md={3} className="mb-2">
            <Button className="h-100" variant="primary" block onClick={() => handleInsuranceCompanyCategoriesCreate(ic)}>
              {ic.name}
            </Button>
          </Col>
        )
      })}
    </Row>
  );

  const handleInsuranceCompanyCategoriesModalCreate = () => {
    setModalTitle("Crear Categorías para:");
    setModalBody(renderInsuranceCompanyCategoriesModalBody);
    setModalShow(true);
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-3">
            <Col>
              <h2 className="text-uppercase">Categorías</h2>
            </Col>
            <Col md={2}>
              <Button className="h-100" variant="primary" block onClick={handleModalCreate}>
                Crear
              </Button>
            </Col>
            {currentCompany.worksWithInsuranceCompanies &&
              <Col md={2}>
                <Button variant="outline-primary" block onClick={handleInsuranceCompanyCategoriesModalCreate}>
                  Crear Categorías para Compañías de Seguros
                </Button>
              </Col>
            }
          </Row>
          <ProductCategoryDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />

          <SimpleCenteredModal
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            onHide={handleModalClose}
            centered={false}
            closeButton
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductCategoryIndex;
