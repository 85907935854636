import React, { useEffect, useMemo, useState } from 'react';
import { Card, Container, Dropdown, Row } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useQueryClient } from 'react-query';
import Cards from './Cards';
import { ChangeColumnNameModal, DeleteColumnModal } from './components/modal/kanban-modals';
import SkeletonCards from './components/skeleton/SkeletonCards';
import {
  usePostProjects, useUpdateKanbanChangeColumn,
  useUpdateKanbanColumnOrder,
  useUpdateKanbanColumnPosition
} from './service';
import './styles.scss';
import { SendModal, SendModalSimple } from './components/send-modal';
import { sendAlert } from '../../app/actions/utils';
import { updateProjectRequest } from '../../app/requests/projects';
import { useIsAdminOrWorkshopManager } from '../../app/selectors';
import { debounce, formatAmount } from "../../app/utils/utils";

const filterByCode = ['in_process', 'budget_sent', 'budget_approved', 'partial_budget', 'warranty', 'ot_completed'];

const Column = ({ column, sections, filters, branch_office_id, sectionCode }) => {
  const canShowTotalPrices = useIsAdminOrWorkshopManager();
  const buildQueryParams = useMemo(() => {
    const queryParams = {
      q: {
        branch_office_id_eq: branch_office_id,
        is_quote_eq: false,
        code_not_null: true,
        ot_state_not_in: [5, 8, 3],
        kanban_column_id: column.id
      }
    };

    if (filters.name && filters.code) {
      queryParams.q.g = [
        {
          m: 'or',
          client_name_or_client_first_last_name_or_vehicle_vehicle_model_name_or_vehicle_vehicle_model_vehicle_brand_name_i_cont:
          filters.name,
          code_in: filters.code
        }
      ];
    }

    if (filters.sellerId) {
      queryParams.q.seller_id_eq = filters.sellerId;
    }

    if (filters.userId) {
      queryParams.q.user_id_eq = filters.userId;
    }

    if (filters.promiseDateOverdue) {
      queryParams.q.promise_date_lteq = new Date().toISOString();
    }

    return queryParams;
  }, [branch_office_id, filters]);
  const { data: projects = [], isLoading: fetchingProjects } = usePostProjects(buildQueryParams, column.id);

  const orderMap = column.order.reduce((acc, id, index) => {
    acc[id] = index;
    return acc;
  }, {});

  projects.sort((a, b) => {
    return orderMap[a.id] - orderMap[b.id];
  });

  const newProjects = projects.map(project => ({
    ...project,
    sectionCode
  }));

  const dispatch = useDispatch();
  const { mutate: updateKanbanChangeColumn } = useUpdateKanbanChangeColumn();
  const { mutate: updateProjectPosition } = useUpdateKanbanColumnPosition();
  const { mutate: updateColumnOrder } = useUpdateKanbanColumnOrder();
  const [showModal, setShowModal] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [allColumns, setAllColumns] = useState({});
  const queryClient = useQueryClient();

  // ------------------handles component -------------------

  useEffect(() => {
    if (sections) {
      const allColumnsContext = sections
        ?.map(sec => sec.columns)
        ?.flat()
        ?.reduce((acc, columns) => {
          acc[columns.id] = [...new Set(columns.order.map(id => Number(id)))];
          return acc;
        }, {});
      setAllColumns(allColumnsContext);
    }
  }, [sections]);

  const findColumn = id => {
    if (id in allColumns) {
      return id;
    }

    return Object.keys(allColumns).find(key => allColumns[key].includes(id));
  };

  const sectionCodeByColumnId = columnId => {
    const section = sections.find(s => s.columns.some(col => col.id === columnId));
    return section ? section.code : null;
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: 'project',
    drop: async dragItem => {
      const { id } = dragItem.item;

      const activeSectionCode = dragItem.item.sectionCode;
      const activeContainer = findColumn(id);
      const overSectionId = findColumn(column.id);
      const overSectionCode = sectionCodeByColumnId(overSectionId);
      if(activeSectionCode === overSectionCode && Number(overSectionId) === Number(activeContainer)) {
        return
      }

      if(activeSectionCode === overSectionCode) {
        updateKanbanChangeColumn({
          origin_id: activeContainer,
          destiny_id: overSectionId,
          project_code: id
        }, {
          onSuccess: () => {
            queryClient.invalidateQueries(['kanban_search_projects', Number(overSectionId)]);
            queryClient.invalidateQueries(['kanban_search_projects', Number(activeContainer)]);
            queryClient.invalidateQueries('kanban_sections');
          }
        })
      }

      if (activeSectionCode !== overSectionCode) {
        if (activeSectionCode === 'in_process' && overSectionCode === 'budget_sent') {
          setShowModal(<SendModal isOpen onClose={() => setShowModal(null)} project={dragItem.item} />);
        } else if (
          (activeSectionCode === 'in_process' || activeSectionCode === 'budget_sent') &&
          overSectionCode === 'working'
        ) {
          setShowModal(
            <SendModalSimple
              showModal
              setModalShow={setShowModal}
              project={dragItem.item}
              buildQueryParams={buildQueryParams}
            />
          );
        }
      }

      if (!activeContainer || !overSectionId) {
        return;
      }

      if (activeSectionCode !== 'budget_sent' && overSectionCode === 'budget_sent') {
        const projectData = { project: { ot_state: 'budget_sent' } };
        updateProjectRequest(id, {
          dispatch,
          params: snakeCaseKeys(projectData),
          formData: true,
          successCallback: () => {
            queryClient.invalidateQueries(['kanban_search_projects', Number(overSectionId)]);
            queryClient.invalidateQueries(['kanban_search_projects', Number(activeContainer)]);
            queryClient.invalidateQueries('kanban_sections');
            dispatch(sendAlert({ kind: 'success', message: 'Estado modificado exitosamente' }));
          },
          failureCallback: error => {
            dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
          }
        });
      }

      if (activeSectionCode !== 'in_process' && overSectionCode === 'in_process') {
        const projectData = { project: { ot_state: 'in_process' } };
        updateProjectRequest(id, {
          dispatch,
          params: snakeCaseKeys(projectData),
          successCallback: () => {
            queryClient.invalidateQueries(['kanban_search_projects', Number(overSectionId)]);
            queryClient.invalidateQueries(['kanban_search_projects', Number(activeContainer)]);
            queryClient.invalidateQueries('kanban_sections');
            dispatch(sendAlert({ kind: 'success', message: 'Estado modificado exitosamente' }));
          },
          failureCallback: error => {
            dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
          }
        });
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  function openModal(type) {
    setModalType(type);
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
    setModalType(null);
  }

  if (!column) {
    return null;
  }

  const moveProject = debounce(async (dragIndex, hoverIndex, columnId, id) => {
    updateProjectPosition({ columnId, project_code: id, position: hoverIndex },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['kanban_search_projects', buildQueryParams]);
        }
      }
    );
  }, 300);

  const totalPrice = newProjects?.reduce((total, project) => total + Number(project?.total), 0);

  return (
    <Container className="d-flex px-0 mx-0 flex-column kanban-columns">
      <div className="d-flex align-items-center p-1 m-1 kanban-column-container">
        <span className="mx-1 kanban-column-name">{column.name}</span>
        <span className="mr-auto" style={{ color: '#cdcdcd' }}>
          {column?.order?.length}
        </span>
        {/* menu de columnas */}
        <Dropdown>
          <Dropdown.Toggle as="div" id="dropdown-custom-components" className="custom-toggle">
            <i className="bi bi-list" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {column?.can_delete && <Dropdown.Item onClick={() => openModal('delete')}>Eliminar</Dropdown.Item>}
            <Dropdown.Item onClick={() => openModal('edit')}>Editar</Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                updateColumnOrder({
                  id: column?.id,
                  order: 'desc'
                })
              }
            >
              Ordenar de mayor a menor
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                updateColumnOrder({
                  id: column?.id,
                  order: 'asc'
                })
              }
            >
              Ordenar de menor a mayor
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {modalType === 'edit' && (
          <ChangeColumnNameModal
            showModal={showModal}
            closeModal={closeModal}
            selectedColumnId={column.id}
            setShowModal={setShowModal}
          />
        )}
        {modalType === 'delete' && (
          <DeleteColumnModal
            showModal={showModal}
            closeModal={closeModal}
            selectedColumnId={column.id}
            setShowModal={setShowModal}
          />
        )}
      </div>
      <Row
        className={`border-0 m-1 p-0 project-columns ${isHovered ? 'project-columns-hovered' : ''}`}
        style={{ background: isOver ? '#f9f9ff' : 'transparent' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={dropRef}
      >
        {/* render projects */}
        <div>
          {fetchingProjects ? (
            <SkeletonCards />
          ) : (
            newProjects
              ?.filter(project => filterByCode.includes(project.ot_state))
              ?.map(project => (
                <Cards
                  key={project?.id}
                  project={project}
                  index={column.order.indexOf(String(project.id))}
                  moveProject={moveProject}
                  column={column}
                />
              ))
          )}
        </div>
      </Row>
      <Card.Footer className="m-0 p-1 d-flex kanban-column-footer">
        {canShowTotalPrices && <span className="mx-auto font-weight-bolder  ">Total: $ {formatAmount(totalPrice)}</span>}
      </Card.Footer>
      {showModal}
    </Container>
  );
};

export default Column;
